<template>
	<div class="remark-wrapper">
		<b-modal
			:visible="showDefaultModal"
			:title="$t('remark')"
			size="lg"
			no-close-on-backdrop
			centered
			@hidden="showDefaultModal = false"
		>
			<remark
				ref="defRemark"
				:loadedData="defaultRemark"
				:entityId="entityId"
				:entityTypeId="entityTypeId"
				:remarkTypeId="remarkTypeId"
				isModal="true"
				:backmethod="hideDefaultModal"
				:administratorList="administratorList"
			></remark>
			<template v-slot:modal-footer>
				<megau-button
					icon="check"
					:title="$t('saveremark')"
					@handleClick="handleInsertOrUpdateDefault"
					classprop="btn btn-success"
				></megau-button>
				<megau-button
					icon="times"
					@handleClick="handleDeleteDefault"
					:title="$t('deleteremark')"
					classprop="btn btn-danger"
				></megau-button>
			</template>
		</b-modal>
		<b-modal
			:visible="showAcountantModal"
			:title="$t('remarkforaccoun')"
			size="lg"
			no-close-on-backdrop
			centered
			@hidden="showAcountantModal = false"
		>
			<remark
				ref="accRemark"
				:loadedData="this.acountantRemark"
				:isModal="true"
				:backmethod="hideAcountantModal"
				:administratorList="administratorList"
			></remark>
			<template v-slot:modal-footer>
				<megau-button
					icon="check"
					:title="$t('saveremark')"
					@handleClick="handleInsertOrUpdateAccountant"
					classprop="btn btn-success"
				></megau-button>
				<megau-button
					icon="times"
					@handleClick="handleDeleteAccountant"
					:title="$t('deleteremark')"
					classprop="btn btn-danger"
				></megau-button>
			</template>
		</b-modal>
		<div :class="this.remarksWrapperClass">
			<div
				v-if="showDefRem"
				@click="showDefaultModal = true"
				v-b-tooltip.hover="tooltipOpt"
				:title="defaultremTitle"
				class="default-shape"
			>
				{{ defaultremText }}
			</div>
			<div
				v-if="showAcountantRem"
				@click="showAcountantModal = true"
				v-b-tooltip.hover="tooltipOpt"
				:title="acountantremTitle"
				class="acountant-shape"
			>
				{{ acountantremText }}
			</div>
			<div v-b-tooltip.hover :title="$t('addaremark')">
				<font-awesome-icon
					class="addremark-icon"
					v-if="showRemarkAddIcon"
					:icon="['fas', 'ellipsis-h']"
					size="lg"
					cursor="pointer"
					@click="showDefaultModal = true"
				></font-awesome-icon>
			</div>
		</div>
	</div>
</template>

<script>
import enums from '../../services/helpers/enums';
import moment from 'moment';
import apiUser from '../../services/api/user.api';
import utils from '../../services/helpers/utils';

export default {
	props: {
		defaultRemark: { type: Object },
		acountantRemark: { type: Object },
		entityId: { type: Number },
		entityTypeId: { type: Number },
		remarkTypeId: { type: Number },
		refreshMethod: { type: Function },
		administratorList: { Type: Array },
	},
	data() {
		return {
			showDefaultModal: false,
			showAcountantModal: false,
			tooltipOpt: {
				boundary: 'window',
				placement: 'top',
			},
		};
	},

	computed: {
		showDefRem() {
			return this.defaultRemark ? true : false;
		},
		showAcountantRem() {
			return this.acountantRemark ? true : false;
		},
		showRemarkAddIcon() {
			return this.showDefRem ? false : true;
		},
		defaultremTitle() {
			return this.defaultRemark ? this.getRemarkOwner(this.defaultRemark) + this.defaultRemark.Text : '';
		},
		defaultremText() {
			return this.defaultRemark ? moment(this.defaultRemark.Date).format(this.$store.state.lang.DateTimeFormat) : '';
		},
		acountantremTitle() {
			return this.acountantRemark ? this.getRemarkOwner(this.acountantRemark) + this.acountantRemark.Text : '';
		},
		acountantremText() {
			return this.acountantRemark ? moment(this.acountantRemark.Date).format(this.$store.state.lang.DateTimeFormat) : '';
		},
		remarksWrapperClass() {
			return [
				{
					'icons-container d-flex': true,
					'flex-column': this.showDefRem && this.showAcountantRem,
					'flex-row': (this.showDefRem && !this.showAcountantRem) || (!this.showDefRem && this.showAcountantRem),
				},
			];
		},
	},
	methods: {
		getRemarkOwner(remark) {
			if (remark.UserId) {
				let selectedOperator = this.administratorList.find((obj) => obj.id === remark.UserId);
				if (utils.hasValue(selectedOperator)) {
					return this.$i18n.t('solvedby') + selectedOperator.name + '\n';
				}
			}
			return '';
		},
		hideDefaultModal(refreshTable) {
			this.showDefaultModal = false;
			if (!refreshTable) {
				this.refreshMethod();
			}
		},
		hideAcountantModal(refreshTable) {
			this.showAcountantModal = false;
			if (!refreshTable) {
				this.refreshMethod();
			}
		},
		handleDeleteDefault() {
			this.$refs.defRemark.handleDelete(true);
		},
		handleInsertOrUpdateDefault() {
			this.$refs.defRemark.handleInsertOrUpdate(true);
		},
		handleDeleteAccountant() {
			this.$refs.accRemark.handleDelete(true);
		},
		handleInsertOrUpdateAccountant() {
			this.$refs.accRemark.handleInsertOrUpdate(true);
		},
	},
};
</script>

<style lang="scss">
.remark-wrapper {
	.icons-container {
		display: flex;
		justify-content: space-between;
		width: 100%;

		&.flex-column {
			.acountant-shape {
				margin-top: 0.25rem;
			}
		}

		&.flex-row {
			.addremark-icon {
				margin-left: 5px;
			}
		}

		.default-shape {
			@include remarkBadge(115px, 20px, var(--mu-light-red));
		}

		.acountant-shape {
			@include remarkBadge(115px, 20px, var(--mu-light-orange));
		}

		.addremark-icon {
			color: var(--mu-icongray);
		}
	}
}
</style>
