var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "remark-wrapper" },
    [
      _c(
        "b-modal",
        {
          attrs: {
            visible: _vm.showDefaultModal,
            title: _vm.$t("remark"),
            size: "lg",
            "no-close-on-backdrop": "",
            centered: "",
          },
          on: {
            hidden: function ($event) {
              _vm.showDefaultModal = false
            },
          },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function () {
                return [
                  _c("megau-button", {
                    attrs: {
                      icon: "check",
                      title: _vm.$t("saveremark"),
                      classprop: "btn btn-success",
                    },
                    on: { handleClick: _vm.handleInsertOrUpdateDefault },
                  }),
                  _c("megau-button", {
                    attrs: {
                      icon: "times",
                      title: _vm.$t("deleteremark"),
                      classprop: "btn btn-danger",
                    },
                    on: { handleClick: _vm.handleDeleteDefault },
                  }),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("remark", {
            ref: "defRemark",
            attrs: {
              loadedData: _vm.defaultRemark,
              entityId: _vm.entityId,
              entityTypeId: _vm.entityTypeId,
              remarkTypeId: _vm.remarkTypeId,
              isModal: "true",
              backmethod: _vm.hideDefaultModal,
              administratorList: _vm.administratorList,
            },
          }),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            visible: _vm.showAcountantModal,
            title: _vm.$t("remarkforaccoun"),
            size: "lg",
            "no-close-on-backdrop": "",
            centered: "",
          },
          on: {
            hidden: function ($event) {
              _vm.showAcountantModal = false
            },
          },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function () {
                return [
                  _c("megau-button", {
                    attrs: {
                      icon: "check",
                      title: _vm.$t("saveremark"),
                      classprop: "btn btn-success",
                    },
                    on: { handleClick: _vm.handleInsertOrUpdateAccountant },
                  }),
                  _c("megau-button", {
                    attrs: {
                      icon: "times",
                      title: _vm.$t("deleteremark"),
                      classprop: "btn btn-danger",
                    },
                    on: { handleClick: _vm.handleDeleteAccountant },
                  }),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("remark", {
            ref: "accRemark",
            attrs: {
              loadedData: this.acountantRemark,
              isModal: true,
              backmethod: _vm.hideAcountantModal,
              administratorList: _vm.administratorList,
            },
          }),
        ],
        1
      ),
      _c("div", { class: this.remarksWrapperClass }, [
        _vm.showDefRem
          ? _c(
              "div",
              {
                directives: [
                  {
                    name: "b-tooltip",
                    rawName: "v-b-tooltip.hover",
                    value: _vm.tooltipOpt,
                    expression: "tooltipOpt",
                    modifiers: { hover: true },
                  },
                ],
                staticClass: "default-shape",
                attrs: { title: _vm.defaultremTitle },
                on: {
                  click: function ($event) {
                    _vm.showDefaultModal = true
                  },
                },
              },
              [_vm._v("\n\t\t\t" + _vm._s(_vm.defaultremText) + "\n\t\t")]
            )
          : _vm._e(),
        _vm.showAcountantRem
          ? _c(
              "div",
              {
                directives: [
                  {
                    name: "b-tooltip",
                    rawName: "v-b-tooltip.hover",
                    value: _vm.tooltipOpt,
                    expression: "tooltipOpt",
                    modifiers: { hover: true },
                  },
                ],
                staticClass: "acountant-shape",
                attrs: { title: _vm.acountantremTitle },
                on: {
                  click: function ($event) {
                    _vm.showAcountantModal = true
                  },
                },
              },
              [_vm._v("\n\t\t\t" + _vm._s(_vm.acountantremText) + "\n\t\t")]
            )
          : _vm._e(),
        _c(
          "div",
          {
            directives: [
              {
                name: "b-tooltip",
                rawName: "v-b-tooltip.hover",
                modifiers: { hover: true },
              },
            ],
            attrs: { title: _vm.$t("addaremark") },
          },
          [
            _vm.showRemarkAddIcon
              ? _c("font-awesome-icon", {
                  staticClass: "addremark-icon",
                  attrs: {
                    icon: ["fas", "ellipsis-h"],
                    size: "lg",
                    cursor: "pointer",
                  },
                  on: {
                    click: function ($event) {
                      _vm.showDefaultModal = true
                    },
                  },
                })
              : _vm._e(),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }